<template>
    <div>
        <info ref="info" @closeForm="closeForm" v-if="showAdd"></info>
        <!-- <div class="submit-btn-group button-fixed"><el-button>下一步</el-button> <el-button>驳回</el-button></div> -->
    </div>
</template>
<script>
import info from './info.vue';
export default {
    name: 'handler',
    data() {
        return {
            showAdd: false,
        };
    },
    components: {info},
    created() {
        this.init();
    },
    // inject: ['refresh'],
    methods: {
        init() {
            // debugger
            let handleType = this.$route.query.handleType;
            if (handleType && handleType === 'create') {
                this.create(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
            } else if (handleType === 'handle') {
                this.create(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
            } else if (handleType == 'details') {
                this.details(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
            }
        },
        create(item) {
            this.showAdd = true;
            this.$nextTick(() => {
                this.$refs.info.init(item);
            });
        },
        details(item) {
            this.showAdd = true;
            item.isInfo = true;
            this.$nextTick(() => {
                this.$refs.info.init(item);
            });
        },
        closeForm(type) {
            this.showAdd = false;
            // this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
            this.$root.$emit('urgentRepairsRefresh');
        },
    },
};
</script>
<style lang="less" scoped>
.submit-btn-group {
    text-align: center;
}
</style>