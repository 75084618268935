<template>
    <div>
        <div class="form-table">
            <el-card class="card-pub-notice card-ext-center-title">
                <el-form ref="form" size="mini" :model="dataForm.data" :disabled="dataForm.isShowAll">
                    <div style="text-align: center">
                        <el-form-item prop="name">
                            <span style="font-size: 18px">
                                <input
                                    class="factory_name"
                                    v-model="dataForm.data.name"
                                    :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                />{{ nameType }}
                            </span>
                        </el-form-item>
                    </div>
                    <div slot="header">
                        <span>{{ row.processDefinitionName }}</span>
                    </div>
                    <el-form-item prop="code">
                        <span
                            >编号：
                            <input
                                class="factory_name"
                                style="width: 100px"
                                v-model="dataForm.data.code"
                                :disabled="getDisable(NODE_CODE_VALUE.S1)"
                        /></span>
                    </el-form-item>

                    <br />
                    <!-- 启动抢修 -->
                    <el-card v-if="getVisible(NODE_CODE_VALUE.S1)">
                        <div slot="header" class="clearfix">
                            <span>启动抢修</span>
                        </div>
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="抢修工作负责人（监护人）" prop="repairPrincipal">
                                    <el-input
                                        v-model="dataForm.data.repairPrincipal"
                                        placeholder="请输入抢修工作负责人（监护人）"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="单位和班组" prop="untis">
                                    <el-input
                                        v-model="dataForm.data.untis"
                                        placeholder="请输入单位和班组"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="负责人及工作班人员总人数" prop="principalAndUnitsCout">
                                    <el-input
                                        v-model="dataForm.data.principalAndUnitsCout"
                                        placeholder="负责人及工作班人员总人数"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="抢修任务（抢修地点和抢修内容" prop="repairTask">
                                    <el-input
                                        type="textarea"
                                        v-model="dataForm.data.repairTask"
                                        placeholder="请输入抢修任务"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="安全措施及注意事项" prop="safetyMeasure">
                                    <el-input
                                        type="textarea"
                                        v-model="dataForm.data.safetyMeasure"
                                        placeholder="请输入安全措施及注意事项"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="任务布置人" prop="taskArrange">
                                    <el-input
                                        v-model="dataForm.data.taskArrange"
                                        placeholder="请输入任务布置人"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="任务内容" prop="taskContent">
                                    <el-input
                                        type="textarea"
                                        v-model="dataForm.data.taskContent"
                                        placeholder="请输入任务内容"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="工作许可人" prop="workLicence">
                                    <el-select
                                        v-model="dataForm.data.workLicence"
                                        placeholder="请输入工作许可人"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                        ><el-option
                                            v-for="item in userList"
                                            :key="item.username"
                                            :value="item.username"
                                            :label="item.realName"
                                        ></el-option
                                    ></el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="工作负责人" prop="workPrincipal">
                                    <el-select
                                        v-model="dataForm.data.workPrincipal"
                                        placeholder="请输入工作负责人"
                                        :disabled="getDisable(NODE_CODE_VALUE.S1)"
                                        ><el-option
                                            v-for="item in userList"
                                            :key="item.username"
                                            :value="item.username"
                                            :label="item.realName"
                                        ></el-option
                                    ></el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <!-- 抢修许可 -->
                    <el-card v-if="getVisible(NODE_CODE_VALUE.S2) || getVisible(NODE_CODE_VALUE.S3)">
                        <div slot="header" class="clearfix">
                            <span>抢修许可</span>
                        </div>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="经核实确认或需补充调整的安全措施" prop="safetyMeasureReplenish">
                                    <el-input
                                        type="textarea"
                                        v-model="dataForm.data.safetyMeasureReplenish"
                                        :disabled="getDisable(NODE_CODE_VALUE.S2)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-form-item label="工作负责人签名" prop="workPrincipalSign">
                                    <el-input
                                        v-model="dataForm.data.workPrincipalSign"
                                        placeholder="请输入工作负责人签名"
                                        :disabled="getDisable(NODE_CODE_VALUE.S2)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="工作许可人签名" prop="workLicenceSign">
                                    <el-input
                                        v-model="dataForm.data.workLicenceSign"
                                        placeholder="请输入工作许可人签名"
                                        :disabled="getDisable(NODE_CODE_VALUE.S3)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="工作许可人签字时间" prop="workLicenceSignTime">
                                    <el-date-picker
                                        style="width: 100%"
                                        v-model="dataForm.data.workLicenceSignTime"
                                        :disabled="getDisable(NODE_CODE_VALUE.S3)"
                                        type="datetime"
                                        placeholder="选择日期"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <!-- 终结 -->
                    <el-card v-if="getVisible(NODE_CODE_VALUE.S4) || getVisible(NODE_CODE_VALUE.S5)">
                        <div slot="header" class="clearfix">
                            <span>终结</span>
                        </div>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="现场设备状况及保留安全措施" prop="safetyMeasureFinality">
                                    <el-input
                                        type="textarea"
                                        v-model="dataForm.data.safetyMeasureFinality"
                                        placeholder="请输入现场设备状况及保留安全措施"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="灾后抢修专责监护人" prop="repairGuardian">
                                    <el-input
                                        v-model="dataForm.data.repairGuardian"
                                        placeholder="请输入灾后抢修专责监护人"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="抢修结束或转移工作票时间" prop="repairEndTime">
                                    <el-date-picker
                                        style="width: 100%"
                                        v-model="dataForm.data.repairEndTime"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                        type="datetime"
                                        placeholder="选择日期"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="工作许可人签名" prop="workLicenceFinality">
                                    <el-input
                                        v-model="dataForm.data.workLicenceFinality"
                                        placeholder="请输入工作许可人"
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="工作负责人签名" prop="workPrincipalFinality">
                                    <el-input
                                        v-model="dataForm.data.workPrincipalFinality"
                                        placeholder="请输入工作负责人"
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="时间" prop="workLicenceFinalityTime">
                                    <el-date-picker
                                        style="width: 100%"
                                        v-model="dataForm.data.workLicenceFinalityTime"
                                        :disabled="getDisable(NODE_CODE_VALUE.S5)"
                                        type="datetime"
                                        placeholder="选择日期"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="备注" prop="remark">
                                    <el-input
                                        type="textarea"
                                        v-model="dataForm.data.remark"
                                        placeholder=""
                                        :disabled="getDisable(NODE_CODE_VALUE.S4)"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
                        <el-table
                            :data="dataForm.rejectNodes"
                            border
                            highlight-current-row
                            @current-change="handleCurrentChange"
                            style="width: 100%; height: 200px; overflow-y: auto !important"
                        >
                            <el-table-column
                                align="center"
                                fixed
                                prop="name"
                                :show-overflow-tooltip="true"
                                label="步骤名称"
                            >
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center; margin-top: 10px">
                            <el-button type="primary" @click="rejectSubmit">驳回</el-button>
                        </div>
                    </el-dialog>
                </el-form>
            </el-card>
        </div>
        <div class="submit-btn-group button-fixed">
            <template v-if="!dataForm.isInfo">
                <el-button @click="save('save')" type="primary">{{ saveButtonText }}</el-button>
                <el-button @click="save('saveAndNext')" type="primary">{{ submitButtonText }}</el-button>
                <el-button @click="dialogVisible = true" v-if="dataForm.curNodeCode" type="primary">驳回</el-button>
            </template>
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'info',
    data() {
        return {
            row: {},
            nameType: '紧急抢修工作票',
            //环节编码权值
            NODE_CODE_VALUE: {},
            dataForm: {
                instanceId: null, //实例id
                definitionCode: '', //流程编码
                nodeCode: '', //下一环节code
                operationType: '', //操作类型 save saveAndNext reject
                jumpNodeCode: '', //跳转环节
                user: '',
                roles: [],
                nextUsers: null, //下一环节处理人
                nextRoles: null, //下一角色
                curNodeCode: '', //当前环节编码
                isInfo: '', //是否是详情
                isAddName: false, //是否自动补全过名称
                extraProp: {
                    //查询条件
                    code: '',
                    name: '',
                },
                data: {
                    id: '',
                    name: '', //线路名称
                    code: '', //编码
                    type: '', //类型（1-第一种 2-第二种）
                    repairPrincipal: '', //抢修工作负责人（监护人）
                    untis: '', //单位和班组
                    principalAndUnitsCout: '', //负责人及工作班人员总人数
                    repairTask: '', //抢修任务（抢修地点和抢修内容
                    safetyMeasure: '', //安全措施及注意事项
                    taskArrange: '', //任务布置人
                    taskContent: '', //任务内容
                    workLicence: '', //工作许可人
                    workPrincipal: '', //请输入工作负责人
                    safetyMeasureReplenish: '', //经核实确认或需补充调整的安全措施
                    workPrincipalSign: '', //工作负责人签名
                    workLicenceSign: '', //工作许可人签名
                    workLicenceSignTime: '', //工作许可人签字时间
                    safetyMeasureFinality: '', //请输入现场设备状况及保留安全措施
                    workPrincipalFinality: '', //工作负责人
                    workLicenceFinality: '', //工作许可人
                    workLicenceFinalityTime: '', //工作许可人签字时间
                    repairGuardian: '', //灾后抢修专责监护人
                    repairEndTime: '', //抢修结束或转移工作票时间
                },
            },
            userList: [],
            nodeCodeMap: new Map(), //正在处理的环节编码权值，用于判断显示/隐藏及编辑
            saveButtonText: '保存',
            submitButtonText: '保存并发布',
            isShowAll: false,
            dialogVisible: false,
        };
    },
    // inject: ['refresh'],
    created() {
        // 昆自运维
        let orgCodes1 = ['WB01'];
        this.$client.queryUserByOrgCode(orgCodes1).then((data) => {
            this.userList = data.data;
        });
        this.initNodeCodeValue();
        this.initNodeCodeMap();
    },
    computed: {
        getVisible() {
            return function (type) {
                // debugger
                if (this.isShowAll) return true;
                let b = false;
                let v = 1;
                let curNodeCode = this.dataForm.curNodeCode;
                let nextNodeCode = this.dataForm.nodeCode;
                if (curNodeCode) {
                    v = this.dataForm.isInfo
                        ? this.nodeCodeMap.get(curNodeCode) - 1
                        : this.nodeCodeMap.get(curNodeCode);
                }
                let tv = this.nodeCodeMap.get(type);
                switch (type) {
                    case this.NODE_CODE_VALUE.S1:
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S2:
                        b = v >= tv && this.dataForm.data.isSign === '0';
                    case this.NODE_CODE_VALUE.S3:
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S4:
                        b = v >= tv;
                        break;
                    case this.NODE_CODE_VALUE.S5:
                        b = v >= tv;
                        break;
                    default:
                }
                return b;
            };
        },
        //是否可编辑
        getDisable() {
            return function (type) {
                if (this.dataForm.isInfo) {
                    //详情时不可编辑
                    return true;
                }
                let b = false;
                let v = 1;
                let curNodeCode = this.dataForm.curNodeCode;
                // let nextNodeCode = this.dataForm.nodeCode;
                if (curNodeCode) {
                    v = this.dataForm.isInfo
                        ? this.nodeCodeMap.get(curNodeCode) - 1
                        : this.nodeCodeMap.get(curNodeCode);
                    // v = this.nodeCodeMap.get(curNodeCode);
                }
                let tv = this.nodeCodeMap.get(type);
                switch (type) {
                    case this.NODE_CODE_VALUE.S1:
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S2:
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S3:
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S4:
                        b = v !== tv;
                        break;
                    case this.NODE_CODE_VALUE.S5:
                        b = v !== tv;
                        break;
                    default:
                }
                return b;
            };
        },
    },
    methods: {
        async init(item) {
            if (item.instanceId) {
                //编辑
                this.dataForm = item;
            } else {
                //新建
                //获取用户信息
                this.dataForm.user = this.$store.state.userInfo.username;
                this.dataForm.data.createUser = this.$store.state.userInfo.username;
                //this.dataForm.roles = this.$store.state.userRole;
                //新建——初始化
                this.dataForm.data.type = item.code;
                //获取流程参数
                this.dataForm.definitionCode = item.code || item.definitionCode;
                this.dataForm.operationType = 'save';
                //获取instanceId和nodeCode
                await this.$client.workflowInstanceProcess(this.dataForm).then((res) => {
                    if (res.code === 0) {
                        this.dataForm.instanceId = res.data.instance.id;
                        this.dataForm.nodeCode = res.data.nextNode.code;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                        });
                    }
                });
            }
            this.initNodeCodeValue();
            this.isShowAll = item.isShowAll;
            if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S3) {
                this.submitButtonText = '保存并发布';
            } else if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S4) {
                this.submitButtonText = '保存并发布';
            }
        },
        async save(type) {
            let msg = this.dataForm.nodeCode === this.NODE_CODE_VALUE.S3 ? '接收人接收' : '确定保存';

            let confirm = await this.$confirm(msg, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            });
            if (confirm !== 'confirm') {
                return;
            }
            //设置发起人
            if (type === 'saveAndNext' && this.dataForm.data.isFirstSubmit) {
                this.dataForm.data.isFirstSubmit = false;
                this.dataForm.data.workflowCreateBy = this.$store.state.userInfo.realName;
            }
            this.dataForm.data.createTime = this.$dayjs().format('YYYY-MM-DD HH:mm');
            if (!this.dataForm.id) {
                //待接收时需要生成code
                // this.$client.getNextWorkTicketCode(workTicketType).then((res) => {
                // debugger;
                // let code = res.data;
                // this.dataForm.data.code = code;
                if (!this.dataForm.extraProp) {
                    this.dataForm.extraProp = {};
                }
                this.dataForm.extraProp.code = this.dataForm.data.code;
                this.dataForm.extraProp.name = this.dataForm.data.name
                this.doSave(type);
                // });
            } else {
                this.doSave(type);
            }
        },
        initNodeCodeValue() {
            this.NODE_CODE_VALUE.S1 = 'elec_urgentRepairs_ticket_application';
            this.NODE_CODE_VALUE.S2 = 'elec_urgentRepairs_ticket_principal_audit';
            this.NODE_CODE_VALUE.S3 = 'elec_urgentRepairs_ticket_licensor_audit';
            this.NODE_CODE_VALUE.S4 = 'elec_urgentRepairs_ticket_principal_receipt';
            this.NODE_CODE_VALUE.S5 = 'elec_urgentRepairs_ticket_licensor_receipt';
        },
        initNodeCodeMap() {
            this.nodeCodeMap = new Map();
            this.nodeCodeMap.set('elec_urgentRepairs_ticket_application', 1);
            this.nodeCodeMap.set('elec_urgentRepairs_ticket_principal_audit', 2);
            this.nodeCodeMap.set('elec_urgentRepairs_ticket_licensor_audit', 3);
            this.nodeCodeMap.set('elec_urgentRepairs_ticket_principal_receipt', 4);
            this.nodeCodeMap.set('elec_urgentRepairs_ticket_licensor_receipt', 5);
        },

        closeForm(type) {
            this.$emit('closeForm', type);
        },
        doSave(type) {
            this.setSaveParam(type);
            debugger
            //签名校验
            if (!this.getDisable(this.NODE_CODE_VALUE.S1)) {
                if (!this.dataForm.data.workLicence && !this.dataForm.data.workPrincipal) {
                    this.$message.error('请选择工作许可人和工作负责人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S2)) {
                if (!this.dataForm.data.workPrincipalSign) {
                    this.$message.error('请输入工作负责人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S3)) {
                if (!this.dataForm.data.workPrincipalSign) {
                    this.$message.error('请输入工作许可人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S4)) {
                if (!this.dataForm.data.workLicenceFinality) {
                    this.$message.error('请输入工作许可人');
                    return;
                }
            } else if (!this.getDisable(this.NODE_CODE_VALUE.S5)) {
                if (!this.dataForm.data.workPrincipalFinality) {
                    this.$message.error('请输入工作负责人');
                    return;
                }
            }
            this.$client.workflowInstanceProcess(this.dataForm).then((res) => {
                if (res.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                    });
                    this.closeForm(1);
                }
            });
        },
        setSaveParam(type) {
            //处理方式
            debugger
            this.dataForm.operationType = type;
            //是否是保存未提交
            this.dataForm.data.saveNotSubmit = type === 'save';
            if (!this.dataForm.data.saveNotSubmit) {
                // 如果存在接收人，则指定下一步处理人
                if (this.dataForm.curNodeCode === '' || this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S1) {
                    this.dataForm.nextUsers = [
                        // this.dataForm.data.workLicence, //工作许可人
                        this.dataForm.data.workPrincipal, //请输入工作负责人
                    ];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S2) {
                    this.dataForm.nextUsers = [
                        this.dataForm.data.workLicence, //工作许可人
                        // this.dataForm.data.workPrincipal, //请输入工作负责人
                    ];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S3) {
                    this.dataForm.nextUsers = [
                        this.dataForm.data.workLicence, //工作许可人
                        // this.dataForm.data.workPrincipal, //请输入工作负责人
                    ];
                }
                if (this.dataForm.curNodeCode === this.NODE_CODE_VALUE.S4) {
                    //接收后发给变电站人员进入执行中
                    this.dataForm.nextUsers = [
                        // this.dataForm.data.workLicence, //工作许可人
                        this.dataForm.data.workPrincipal, //请输入工作负责人
                    ];
                }
            } else {
                this.dataForm.nextUsers = [this.$store.state.userInfo.username];
            }
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        // 驳回步骤提交
        rejectSubmit() {
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error',
                });
                return;
            }
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: this.dataForm.definitionCode,
                        instanceId: this.dataForm.instanceId,
                        nodeCode: this.dataForm.nodeCode,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data: this.dataForm.data,
                        user: this.dataForm.user,
                        // roles: this.dataForm.roles,
                        nextUsers: [this.currentRow.handler],
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                        });
                        this.closeForm(2);
                    });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.form-table {
    height: calc(100vh - 170px);
    margin-bottom: 10px;
    overflow-y: scroll;
}

.button-fixed {
    margin: 0 !important;
}

/deep/ .card-margin {
    margin-bottom: 20px;
}

::v-deep .el-radio__inner::after {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.15s ease-in;
}

/deep/ .num-rule input::-webkit-outer-spin-button,
/deep/ .num-rule input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

/deep/ .num-rule input[type='number'] {
    -moz-appearance: textfield;
}

/deep/ .el-textarea__inner {
    color: white;
}

.submit-btn-group {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #235eff;
    background: #235eff;
}
.span_button {
    text-align: center;
}
.factory_name:focus {
    outline: none;
}

.factory_name {
    margin-bottom: 20px;
    width: 20%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #ffffff;
    background-color: transparent;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
}
</style>
